import { useFormContext } from 'react-hook-form';
import { DynamicFieldData, SelectOption } from '@interfaces/games/FormGame';

import InputMask from 'react-input-mask';
import { useState } from 'react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';

export const SelectAutoComplete = ({
  fieldName,
  mask,
  defaultValue,
  placeholder,
  hasAutoFocus = false,
  options = [],
  fieldWidth,
  fieldHeight,
  config = {},
  boxshadow,
  formatOut,
}: DynamicFieldData | any) => {
  const {
    register,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useFormContext();

  const textField = register(fieldName, config);
  // const { onChange, ...rest } = selectField;
  const [selectValue, setSelectValue] = useState(
    getValues(fieldName) || defaultValue
  );

  return (
    <AutoComplete
      shouldRenderSuggestions={(value) =>
        formatOut(value).length > 5 &&
        options.find((item: SelectOption) =>
          item.value.startsWith(formatOut(value))
        )
      }
      openOnFocus={false}
      disableFilter={false}
      filter={(query, optionValue) => {
        return optionValue.startsWith(formatOut(query));
      }}
      selectOnFocus={false}
      onSelectOption={(selected)=>{
        setSelectValue(selected.item.value);
        setValue(fieldName, selected.item.value);
      }}
    >
      <AutoCompleteInput
        as={InputMask}
        {...textField}
        defaultValue={defaultValue}
        name={fieldName}
        id={fieldName}
        value={selectValue}
        autoFocus={hasAutoFocus}
        placeholder={placeholder}
        fontFamily={'mainfont'}
        mask={mask}
        errorBorderColor="formFieldErrorColor"
        color={errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'}
        borderColor={
          errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
        }
        onChange={(e) => {
          textField.onChange(e);
          setSelectValue(e.target.value);
          setValue(fieldName, e.target.value);
        }}
        textAlign={'center'}
        fontSize={['20px', '22px']}
        fontWeight={'700'}
        // maxLength={maxLength}
        _focus={{ color: 'formFieldFocusColor' }}
        isDisabled={isSubmitting}
        isloading={isSubmitting.toString()}
        _placeholder={{ color: 'formFieldPlaceHolderColor' }}
        borderRadius={[15]}
        bg={'white.100'}
        maxW={fieldWidth ?? 350}
        w={fieldWidth}
        h={fieldHeight}
        boxShadow={boxshadow}
      />
      <AutoCompleteList>
        {options.map((option: SelectOption, index: number) => (
          <AutoCompleteItem
            key={index}
            value={option.value}
            // textTransform="capitalize"
            color={'formFieldColor'}
          >
            {option.label}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  );
};
