import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DynamicFieldData } from '@interfaces/games/FormGame';
import {
  Box,
  Checkbox,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import { formatReal, parseReal } from '@lib/helpersValidation';
import { SelectAutoComplete } from './components/SelectAutocomplete';

export const DynamicFields = ({
  inputType,
  fieldName,
  text,
  mask,
  defaultValue,
  placeholder,
  hasStepper = true,
  hasAutoFocus = false,
  options = [],
  fieldWidth,
  fieldHeight,
  boxshadow,
  min,
  isMulti,
  isReadOnly,
  onChange,
  config = {},
  isTooltipOpen,
  tooltipLabel,
  maxLength,
  formatOut,
}: DynamicFieldData | any) => {
  const {
    register,
    formState: { errors, isSubmitting },
    setValue,
    control,
    clearErrors,
    trigger,
  } = useFormContext();
  const field = useWatch({ name: fieldName, control });
  const [hasAlreadyValidate, setHasAlreadyValidade] = useState(false);

  useEffect(() => {
    if (errors[fieldName] != undefined) {
      setHasAlreadyValidade(true);
    }
  }, [errors]);

  useEffect(() => {
    if (hasAlreadyValidate) {
      clearErrors();
      trigger();
    }
  }, [field]);

  const [currencyValue, setCurrencyValue] = useState<number>();
  const [showMask, setShowMask] = useState(false);

  useEffect(() => {
    setShowMask(true);
  }, []);
  switch (inputType) {
    case 'text': {
      const textField = register(fieldName, config);
      return (
        <Tooltip
          label={tooltipLabel}
          hasArrow
          placement="auto"
          isOpen={isTooltipOpen}
        >
          {showMask ? (
            <Input
              as={InputMask}
              {...textField}
              defaultValue={defaultValue}
              name={fieldName}
              isReadOnly={isReadOnly}
              id={fieldName}
              autoFocus={hasAutoFocus}
              placeholder={placeholder}
              fontFamily={'mainfont'}
              mask={mask}
              errorBorderColor="formFieldErrorColor"
              color={
                errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
              }
              borderColor={
                errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
              }
              // size={10}
              onChange={(e) => {
                if (onChange != undefined) {
                  onChange(e.target.value);
                }
                textField.onChange(e);
                setValue(fieldName, e.target.value);
              }}
              textAlign={'center'}
              fontSize={['20px', '22px']}
              fontWeight={'700'}
              maxLength={maxLength}
              _focus={{ color: 'formFieldFocusColor' }}
              isDisabled={isSubmitting}
              isloading={isSubmitting.toString()}
              _placeholder={{ color: 'formFieldPlaceHolderColor' }}
              borderRadius={[15]}
              bg={'white.100'}
              maxW={fieldWidth ?? 350}
              w={fieldWidth}
              h={fieldHeight}
              boxShadow={boxshadow}
            />
          ) : (
            <Input
              {...textField}
              defaultValue={defaultValue}
              name={fieldName}
              isReadOnly={isReadOnly}
              id={fieldName}
              autoFocus={hasAutoFocus}
              placeholder={placeholder}
              fontFamily={'mainfont'}
              errorBorderColor="formFieldErrorColor"
              color={
                errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
              }
              borderColor={
                errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
              }
              // size={10}
              onChange={(e) => {
                if (onChange != undefined) {
                  onChange(e.target.value);
                }
                textField.onChange(e);
                setValue(fieldName, e.target.value);
              }}
              textAlign={'center'}
              fontSize={['20px', '22px']}
              fontWeight={'700'}
              maxLength={maxLength}
              _focus={{ color: 'formFieldFocusColor' }}
              isDisabled={isSubmitting}
              _placeholder={{ color: 'formFieldPlaceHolderColor' }}
              borderRadius={[15]}
              bg={'white.100'}
              maxW={fieldWidth ?? 350}
              w={fieldWidth}
              h={fieldHeight}
              boxShadow={boxshadow}
            />
          )}
        </Tooltip>
      );
    }
    case 'textarea': {
      const textAreaField = register(fieldName, config);
      return (
        <Textarea
          {...textAreaField}
          resize="none"
          textAlign={'center'}
          fontSize={['20px', '22px']}
          fontWeight={'700'}
          maxLength={maxLength}
          _focus={{ color: 'formFieldFocusColor' }}
          isDisabled={isSubmitting}
          _placeholder={{ color: 'formFieldPlaceHolderColor' }}
          color={errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'}
          borderColor={
            errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
          }
          borderRadius={[15]}
          bg={'white.100'}
          maxW={fieldWidth ?? 350}
          w={fieldWidth}
          h={fieldHeight}
          boxShadow={boxshadow}
        />
      );
    }
    case 'select': {
      // const selectField = register(fieldName, config);
      return (
        <Box
          color={errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'}
        >
          <Controller
            control={control}
            name={fieldName}
            rules={config}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                // {...register(fieldName, config)}
                defaultValue={defaultValue}
                // id={fieldName}
                placeholder={placeholder}
                autoFocus={hasAutoFocus}
                isMulti={isMulti}
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorBorderColor="formFieldErrorColor"
                colorScheme="blue"
                // onChange={(newValue) => {
                //   setValue(
                //     fieldName,
                //     newValue.map((item: any) => item.value)
                //   );
                //   selectField.onChange({ target: { value: newValue } });
                // }}
                chakraStyles={{
                  control: (provided, state) => ({
                    ...provided,
                    fontFamily: 'mainfont',
                    color: state.isRtl
                      ? 'formFieldPlaceHolderColor'
                      : state.isFocused
                      ? 'formFieldFocusColor'
                      : errors[fieldName]
                      ? 'formFieldErrorColor'
                      : 'formFieldColor',
                    borderColor: errors[fieldName]
                      ? 'formFieldErrorColor'
                      : 'formFieldColor',
                    // boxSize: 10,
                    textAlign: 'center',
                    fontSize: ['20px', '25px'],
                    fontWeight: '700',
                    borderRadius: [15],
                    bg: 'white',
                    // maxW: 350,
                    w: 350,
                  }),
                }}
                // size={10}
                // textAlign={'center'}
                // fontSize={['20px', '25px']}
                // fontWeight={'700'}
                // _focus={{ color: 'formFieldFocusColor' }}
                isDisabled={isSubmitting}
                // _placeholder={{ color: 'formFieldPlaceHolderColor' }}
                // borderRadius={[2, 15]}
                // bg={'white'}
                // maxW={350}
                options={options}
              />
            )}
          />
        </Box>
      );
    }
    case 'select-autocomplete': {
      return (
        <SelectAutoComplete
          fieldName={fieldName}
          mask={mask}
          defaultValue={defaultValue}
          placeholder={placeholder}
          hasAutoFocus={hasAutoFocus}
          options={options}
          fieldWidth={fieldWidth}
          fieldHeight={fieldHeight}
          isMulti={isMulti}
          config={config}
          formatOut={formatOut}
          boxshadow={boxshadow}
        />
      );
    }
    case 'number':
      return (
        <NumberInput
          id={fieldName}
          defaultValue={defaultValue}
          errorBorderColor="formFieldErrorColor"
          isDisabled={isSubmitting}
          isReadOnly={isReadOnly}
        >
          <NumberInputField
            {...register(fieldName, config)}
            autoFocus={hasAutoFocus}
            fontFamily={'mainfont'}
            color={errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'}
            borderColor={
              errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
            }
            // size={10}
            textAlign={'center'}
            fontSize={['20px', '25px']}
            fontWeight={'700'}
            _focus={{ color: 'formFieldFocusColor' }}
            _placeholder={{ color: 'formFieldPlaceHolderColor' }}
            borderRadius={[15]}
            maxLength={maxLength}
            min={min}
            bg={'white.100'}
            maxW={fieldWidth ?? 350}
          />
          {hasStepper == true && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </NumberInput>
      );
    case 'checkbox':
      return (
        <Checkbox
          id={fieldName}
          size={['sm', 'md']}
          colorScheme="orange"
          borderColor="#DD6B20"
          textAlign={'left'}
          {...register(fieldName, config)}
          defaultValue={defaultValue}
        >
          <Text fontSize={['sm', 'md']}>{text}</Text>
        </Checkbox>
      );
    case 'currency': {
      return (
        <NumberInput
          id={fieldName}
          defaultValue={defaultValue}
          errorBorderColor="formFieldErrorColor"
          isDisabled={isSubmitting}
          format={(val) => `R$ ` + val}
          isValidCharacter={(character: string) => /^[0-9]$/.test(character)}
          clampValueOnBlur={false}
          onChange={(valueAsString, _) =>
            setCurrencyValue(parseReal(valueAsString))
          }
          min={min}
          value={formatReal(currencyValue ?? 0)}
          w={fieldWidth}
          h={fieldHeight}
          boxShadow={boxshadow}
        >
          <NumberInputField
            pattern={undefined}
            {...register(fieldName, config)}
            autoFocus={hasAutoFocus}
            fontFamily={'mainfont'}
            maxLength={maxLength}
            color={errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'}
            borderColor={
              errors[fieldName] ? 'formFieldErrorColor' : 'formFieldColor'
            }
            // size={10}
            textAlign={'center'}
            fontSize={['20px', '25px']}
            fontWeight={'700'}
            _focus={{ color: 'formFieldFocusColor' }}
            _placeholder={{ color: 'formFieldPlaceHolderColor' }}
            borderRadius={[15]}
            bg={'white.100'}
            maxW={fieldWidth ?? 350}
          />
        </NumberInput>
      );
    }
    default:
      return <input type="text" />;
  }
};
